import * as React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { graphql } from 'gatsby';
// import Img from 'gatsby-image';

// markup
const Omeni = ({ data }) => {
	return (
		<Layout>
			<SEO
				title="Pedja the Coach - Online trainings"
				keywords={[ `online training, gym training, personal trainer` ]}
			/>
		</Layout>
	);
};

export default Omeni;

export const query = graphql`
	query {
		gatsbyImage: file(relativePath: { eq: "icon.png" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
